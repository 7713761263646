import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1600.000000 1600.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M8205 13394 c-346 -31 -439 -48 -680 -122 -296 -91 -510 -196 -795
-390 -282 -192 -557 -484 -730 -777 -146 -246 -212 -404 -260 -615 -11 -52
-25 -113 -31 -135 -16 -65 -23 -408 -10 -508 64 -481 352 -899 746 -1083 164
-76 321 -114 505 -121 167 -6 339 12 491 52 52 14 132 34 179 45 198 48 203
55 60 75 -217 32 -441 131 -601 268 -206 176 -322 377 -385 668 -26 117 -26
384 0 524 64 345 257 699 527 962 108 105 122 116 259 207 389 260 815 354
1350 301 288 -29 524 -93 826 -225 108 -47 171 -81 323 -175 98 -61 348 -264
427 -347 307 -325 512 -696 618 -1117 20 -78 36 -151 36 -161 0 -11 5 -22 11
-26 18 -11 6 426 -15 561 -28 175 -84 390 -124 470 -11 22 -48 97 -82 167
-103 208 -294 469 -492 669 -109 111 -151 149 -268 241 -159 125 -532 339
-695 398 -283 103 -462 148 -685 174 -126 15 -430 26 -505 20z"
          />
          <path
            d="M9245 10125 c-11 -2 -74 -9 -140 -15 -66 -6 -133 -15 -150 -20 -16
-5 -48 -11 -70 -14 -90 -13 -115 -18 -115 -25 0 -4 -14 -6 -31 -3 -16 2 -47
-3 -67 -11 -20 -9 -46 -18 -57 -20 -12 -3 -8 -5 12 -6 17 0 38 -8 47 -16 19
-20 126 -26 126 -7 0 8 8 10 28 6 36 -9 40 -14 11 -15 -22 0 -23 -1 -4 -9 17
-7 17 -9 3 -9 -21 -1 -24 -27 -5 -34 10 -4 10 -6 0 -6 -7 -1 -13 -6 -13 -12 0
-6 6 -9 14 -6 7 3 18 1 24 -5 16 -16 3 -100 -19 -121 -13 -12 -18 -30 -17 -60
l1 -42 4 43 c3 34 8 42 25 42 19 0 20 -4 13 -80 -5 -54 -4 -80 4 -80 6 0 12
33 14 83 2 60 3 47 5 -50 1 -82 -2 -134 -8 -138 -6 -4 -10 -21 -8 -39 1 -17
-2 -38 -7 -45 -16 -27 -13 -105 5 -118 10 -7 20 -30 24 -53 6 -41 19 -52 30
-24 3 9 11 14 17 12 6 -2 12 -57 14 -148 2 -80 8 -179 13 -220 8 -69 0 -124
-19 -140 -3 -3 -9 -28 -12 -55 -5 -46 -3 -52 16 -60 15 -7 23 -21 25 -43 2
-24 7 -31 15 -24 13 10 62 24 53 15 -3 -4 0 -13 7 -21 10 -13 8 -14 -11 -8
-16 5 -24 3 -28 -9 -4 -9 -10 -12 -13 -7 -4 5 -20 4 -41 -3 l-35 -12 48 -7
c26 -4 47 -11 47 -16 0 -11 11 -9 170 30 19 4 76 16 125 25 50 8 106 20 125
25 48 12 212 36 350 52 163 18 600 15 760 -6 212 -27 457 -80 590 -125 526
-182 830 -354 1340 -760 123 -98 484 -459 631 -631 191 -224 407 -518 506
-690 27 -47 66 -111 86 -142 20 -31 46 -75 57 -98 25 -49 33 -48 20 1 -5 18
-19 77 -30 129 -58 255 -220 724 -355 1025 -35 79 -190 387 -223 445 -54 93
-247 397 -305 479 -220 312 -462 585 -732 829 -603 544 -1237 876 -1940 1017
-169 34 -313 46 -620 50 -170 2 -319 2 -330 0z m-385 -89 c0 -2 -7 -7 -16 -10
-8 -3 -12 -2 -9 4 6 10 25 14 25 6z m107 -12 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-90 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z"
          />
          <path
            d="M8523 9997 c-25 -13 -32 -33 -8 -23 8 3 23 9 32 13 12 5 15 2 10 -10
-3 -9 -2 -17 3 -17 6 0 10 7 10 16 0 9 5 12 11 8 7 -4 10 -1 7 7 -6 18 -36 20
-65 6z"
          />
          <path d="M8428 9963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M8470 9950 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M8285 9935 c-23 -11 -25 -14 -10 -14 11 0 31 6 45 14 32 18 5 18 -35
0z"
          />
          <path d="M8190 9910 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z" />
          <path d="M8103 9883 c-20 -7 -15 -23 6 -23 10 0 21 7 25 15 5 15 -7 18 -31 8z" />
          <path d="M8058 9863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M8800 9850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M7875 9814 c-39 -13 -39 -13 -6 -9 18 3 42 5 52 5 11 0 19 5 19 10 0
13 -16 12 -65 -6z"
          />
          <path d="M8711 9424 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path
            d="M8800 9309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
          />
          <path d="M8903 9080 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
          <path d="M8870 9033 c1 -18 18 -35 24 -24 4 6 4 16 1 21 -7 12 -25 13 -25 3z" />
          <path
            d="M8873 8680 c1 -29 -3 -49 -13 -57 -13 -11 -13 -12 2 -13 24 0 26 8
18 65 l-8 50 1 -45z"
          />
          <path d="M8888 8483 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M8945 8453 c-11 -3 -24 -9 -29 -13 -7 -7 62 4 89 15 14 5 -36 4 -60
-2z"
          />
          <path
            d="M8828 8423 c-16 -2 -28 -9 -28 -15 0 -12 49 -2 65 13 6 6 6 8 0 8 -6
-1 -22 -4 -37 -6z"
          />
          <path
            d="M8715 8385 c-40 -18 -20 -18 30 0 22 8 31 14 20 14 -11 0 -33 -6 -50
-14z"
          />
          <path
            d="M8620 8386 c0 -2 9 -6 20 -9 11 -3 20 -1 20 4 0 5 -9 9 -20 9 -11 0
-20 -2 -20 -4z"
          />
          <path
            d="M8590 8350 c-25 -5 -31 -9 -19 -14 15 -7 69 8 69 19 0 3 -3 4 -7 4
-5 -1 -24 -5 -43 -9z"
          />
          <path
            d="M8515 8323 c-23 -6 -45 -23 -28 -23 6 0 26 7 44 15 33 14 25 18 -16
8z"
          />
          <path
            d="M8393 8283 c0 -7 5 -13 12 -13 6 0 19 6 28 13 15 11 14 12 -11 12
-16 0 -29 -5 -29 -12z"
          />
          <path d="M10035 8151 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
          <path
            d="M10153 8154 c3 -4 17 -8 29 -9 43 -5 78 -24 78 -42 0 -11 3 -14 8 -7
12 19 51 23 67 7 12 -11 15 -11 15 -1 0 10 3 9 10 -2 7 -11 10 -12 10 -2 0 10
4 9 16 -3 9 -9 23 -14 31 -11 8 3 30 6 49 6 21 0 33 4 29 10 -3 6 -16 10 -28
10 -21 1 -21 1 3 15 33 19 54 19 47 0 -4 -8 -2 -17 4 -20 5 -4 7 -11 4 -16 -4
-5 -2 -9 3 -9 6 0 13 -12 17 -27 5 -21 8 -24 16 -12 8 12 10 12 18 -1 5 -8 7
-18 4 -24 -3 -5 -1 -6 5 -2 15 9 26 -36 19 -80 -5 -30 -3 -36 18 -44 21 -8 24
-14 19 -40 -4 -17 -2 -36 5 -44 16 -19 17 -209 2 -214 -14 -5 -14 -89 -1 -97
6 -3 10 -44 10 -90 0 -46 -4 -87 -10 -90 -15 -9 -12 -41 5 -55 8 -7 12 -16 9
-22 -6 -10 13 -38 27 -38 4 0 9 10 11 23 3 19 11 23 58 28 30 3 59 2 64 -2 6
-5 -15 -9 -47 -9 -31 0 -57 -4 -57 -10 0 -5 23 -10 50 -10 28 0 50 -4 50 -9 0
-12 -29 -23 -41 -15 -5 3 -17 0 -26 -5 -15 -9 -11 -10 18 -11 24 0 39 -5 43
-16 9 -25 -3 -36 -34 -29 -15 3 -31 2 -34 -3 -3 -6 -25 -8 -49 -5 -23 3 -38 2
-34 -3 4 -4 20 -9 35 -10 15 -1 45 -5 67 -8 29 -4 34 -4 20 3 -13 6 -15 10 -5
10 8 0 24 -6 34 -14 12 -9 42 -15 77 -15 31 0 93 -7 136 -15 95 -18 130 -19
136 -2 3 7 6 5 6 -5 1 -19 8 -21 111 -39 35 -6 82 -16 105 -23 22 -7 83 -25
135 -40 110 -32 202 -62 245 -81 17 -7 63 -25 104 -39 123 -44 365 -167 561
-285 240 -145 207 -123 430 -283 96 -69 320 -245 340 -268 3 -3 25 -21 50 -40
124 -95 532 -498 673 -664 23 -27 61 -71 84 -98 24 -27 50 -60 59 -74 9 -13
19 -22 22 -20 3 3 1 11 -5 18 -5 7 -16 33 -23 58 -8 25 -35 90 -60 145 -26 55
-50 107 -54 115 -3 8 -30 60 -60 115 -30 55 -73 138 -96 184 -43 86 -172 301
-241 401 -21 32 -39 62 -39 68 0 6 -3 12 -7 14 -5 2 -23 26 -42 53 -62 91
-258 349 -311 410 -159 182 -484 514 -566 577 -16 13 -65 54 -109 92 -145 124
-347 265 -548 380 -158 90 -471 236 -509 236 -8 0 -18 4 -23 9 -14 12 -195 69
-295 92 -85 19 -263 48 -414 67 -87 11 -121 2 -112 -31 3 -11 0 -17 -7 -14 -7
2 -12 15 -12 28 l0 24 -205 3 c-112 1 -201 0 -197 -4z m514 -130 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m178 -874 c3 -5 1 -10 -4 -10 -6 0
-11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m51 -15 c-21 -16 -32 -13 -21 4
3 6 14 11 23 11 15 -1 15 -2 -2 -15z m239 -37 c6 -4 -8 -7 -30 -6 -23 0 -30 3
-17 5 12 2 22 9 22 15 0 7 3 7 8 2 4 -5 12 -12 17 -16z"
          />
          <path d="M9833 8118 c21 -7 32 -7 40 1 8 8 0 11 -28 10 -40 -1 -40 -1 -12 -11z" />
          <path d="M9925 8120 c-4 -6 3 -10 14 -10 25 0 27 6 6 13 -8 4 -17 2 -20 -3z" />
          <path d="M10151 8121 c-10 -6 -9 -10 3 -14 8 -3 18 -2 21 3 8 13 -9 20 -24 11z" />
          <path
            d="M10385 8040 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
          />
          <path
            d="M10567 7893 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13
18z"
          />
          <path
            d="M10530 7753 c-21 -28 -19 -46 6 -36 9 3 14 13 11 24 -3 11 -1 19 4
19 5 0 9 -7 9 -16 0 -9 7 -22 16 -29 10 -7 19 -32 22 -63 3 -35 9 -52 18 -52
21 0 19 79 -2 87 -9 3 -11 8 -6 12 6 3 -4 22 -24 43 l-33 37 -21 -26z"
          />
          <path
            d="M10467 7563 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13
18z"
          />
          <path d="M10748 7163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M10585 7130 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10
-8 0 -18 -4 -21 -10z"
          />
          <path
            d="M9685 6135 c-11 -2 -69 -9 -130 -15 -201 -21 -327 -58 -451 -131
-243 -143 -338 -392 -338 -874 1 -388 53 -593 194 -758 53 -62 196 -150 303
-185 138 -46 257 -62 497 -69 239 -6 439 6 593 38 294 59 479 205 548 431 21
71 45 249 36 272 -6 14 -35 16 -235 16 -172 0 -232 -3 -239 -12 -6 -7 -13 -33
-15 -58 -10 -91 -58 -157 -143 -196 -89 -40 -162 -48 -450 -48 -225 0 -281 3
-334 17 -99 27 -161 65 -199 121 -61 91 -67 130 -67 426 0 254 1 268 24 340
21 63 34 84 80 131 46 45 67 58 118 73 96 26 287 39 508 33 258 -7 324 -24
397 -105 34 -37 48 -77 61 -167 2 -19 9 -20 242 -17 132 2 243 6 246 10 4 4 3
43 -2 86 -28 248 -125 408 -310 513 -160 91 -327 122 -689 128 -124 2 -234 2
-245 0z"
          />
          <path
            d="M4763 5852 c-154 -339 -242 -531 -298 -648 -26 -54 -59 -124 -72
-154 -14 -30 -58 -125 -98 -210 -76 -161 -160 -344 -238 -515 -25 -55 -73
-158 -106 -228 -34 -71 -61 -130 -61 -132 0 -2 -25 -57 -56 -122 -94 -197
-124 -265 -124 -279 0 -12 45 -14 254 -14 l253 0 43 98 c23 53 79 176 123 272
88 194 167 369 199 445 12 28 27 53 32 57 16 11 1010 10 1039 -1 15 -6 30 -26
42 -58 10 -26 39 -90 63 -140 l45 -93 248 0 c137 0 249 3 249 7 0 15 -75 185
-289 656 -45 98 -81 180 -81 182 0 2 -24 55 -54 117 -120 252 -379 819 -442
966 l-18 42 -270 0 -271 0 -112 -248z m450 -384 c36 -79 92 -206 125 -283 33
-77 79 -180 101 -230 22 -49 41 -96 41 -102 0 -10 -73 -13 -333 -13 -184 0
-336 3 -339 6 -4 3 10 40 29 82 33 72 264 610 284 660 5 12 12 22 17 22 5 0
39 -64 75 -142z"
          />
          <path
            d="M6450 5115 l0 -985 579 0 c333 0 622 4 681 11 497 50 703 210 792
614 29 131 36 474 14 646 -56 431 -269 630 -736 688 -51 7 -332 11 -707 11
l-623 0 0 -985z m1380 502 c104 -43 146 -93 185 -217 13 -41 19 -103 22 -251
10 -383 -40 -484 -267 -546 -43 -12 -138 -17 -442 -20 l-388 -5 0 537 0 536
413 -3 412 -3 65 -28z"
          />
          <path
            d="M6627 3834 c-4 -4 -7 -119 -7 -256 0 -136 -3 -248 -6 -248 -4 0 -39
12 -78 27 -60 22 -88 26 -176 27 -168 1 -267 -41 -357 -151 -62 -77 -85 -157
-90 -318 -6 -148 8 -221 58 -316 97 -182 353 -243 575 -138 76 36 82 36 86 2
l3 -28 113 -3 112 -3 2 691 c0 379 1 696 2 703 1 9 -30 13 -115 15 -63 1 -118
-1 -122 -4z m-60 -679 l53 -24 0 -225 0 -225 -27 -19 c-56 -40 -106 -53 -194
-54 -123 0 -180 35 -217 134 -50 136 -32 306 42 386 66 73 217 85 343 27z"
          />
          <path
            d="M8441 3618 c-47 -10 -87 -21 -90 -24 -4 -3 -8 -56 -11 -117 l-5 -112
-72 -3 -73 -3 0 -94 0 -95 74 0 74 0 4 -282 c3 -242 7 -289 22 -323 59 -135
213 -180 457 -134 l57 11 6 45 c8 63 8 141 -2 147 -4 2 -32 -2 -62 -10 -117
-30 -205 -9 -227 56 -8 23 -12 114 -12 261 -1 198 1 228 15 233 9 3 75 6 148
6 l133 0 7 87 c3 48 3 90 -2 95 -4 4 -73 9 -152 10 l-145 3 3 133 4 132 -34
-1 c-18 -1 -71 -10 -117 -21z"
          />
          <path
            d="M7445 3380 c-103 -10 -201 -28 -241 -45 l-30 -12 9 -79 c4 -44 10
-84 12 -91 3 -8 24 -6 77 8 93 24 256 42 305 34 113 -19 153 -56 153 -139 l0
-53 -157 1 c-144 0 -165 -2 -244 -27 -155 -48 -207 -108 -216 -247 -14 -195
84 -302 287 -317 82 -6 206 19 281 57 50 26 59 25 59 -5 0 -31 32 -38 150 -32
l85 4 3 279 c2 309 -8 455 -37 512 -37 72 -99 118 -191 140 -65 15 -213 21
-305 12z m283 -632 c3 -83 2 -88 -22 -103 -43 -28 -118 -46 -197 -46 -121 -1
-163 30 -157 118 3 39 8 50 40 75 43 36 102 46 238 44 l95 -1 3 -87z"
          />
          <path
            d="M9450 3384 c-91 -8 -206 -26 -263 -41 l-58 -16 5 -36 c3 -20 8 -62
11 -94 5 -48 9 -57 23 -53 72 22 151 38 232 47 199 21 290 -22 290 -135 l0
-53 -147 1 c-231 1 -368 -43 -437 -142 -27 -38 -30 -52 -34 -138 -5 -111 10
-164 65 -225 72 -79 232 -109 382 -70 46 12 101 31 122 41 50 26 59 25 59 -4
0 -21 6 -26 43 -31 23 -3 76 -4 117 -2 l75 3 0 350 c0 318 -2 354 -19 408 -31
93 -100 150 -211 174 -58 13 -190 21 -255 16z m235 -639 l0 -90 -50 -25 c-40
-20 -69 -25 -146 -28 -139 -6 -179 19 -179 110 1 95 75 127 280 124 l95 -1 0
-90z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
